import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { SelectedGroupService } from 'src/app/views/app/teams-page/selected-group-service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-team-name',
  templateUrl: './modal-team-name.component.html',
})

export class ModalTeamNameComponent implements OnInit {
  private authHeader = this.tokenService.getToken(); 
  private selectedWorkspaceId: number;
  private selectedGroupId: number;
  selectedGroupName: string;
  name: string;
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService, 
    private selectedGroupService: SelectedGroupService,
    private tokenService: TokenService,
    private http: HttpClient,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => 
          this.selectedWorkspaceId = workspaceId );
      this.selectedGroupService.selectedGroupId$.subscribe(groupId => 
        this.selectedGroupId = groupId );
      this.selectedGroupService.selectedGroupName$.subscribe(groupName => 
        this.name = groupName );
    }

    teamName(){ // API Call change-team-name 
      this.http
      .put(`${environment.apiUrl}/v1/groups/${this.selectedGroupId}`,  { 
        name: this.name, 
        teamId: this.selectedWorkspaceId }, { 
          headers: { Authorization: this.authHeader } })
      .subscribe((resData) => { 
          this.modalRef.hide();
          this.dataService.notifyDataUploaded();
          this.notifications.create('Team name changed', 'Team name successfully changed!', 
            NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
        (error) => {
          this.modalRef.hide();
          this.notifications.create('Error', error.error.message,
            NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
        );      
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
    this.modalService.onHidden.subscribe(() => {
      this.name = '';
    });
  }

  closeModal(): void{
    this.modalRef.hide();
    this.name = '';
  }
}

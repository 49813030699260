import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule } from '@angular/forms';
import { ModalTemplateComponent } from './modal-template/modal-template.component';
import { ModalDeleteTemplateComponent } from './modal-delete-template/modal-delete-template.component';
import { ModalCopyTemplateComponent } from './modal-copy-template/modal-copy-template.component';
import { ModalPasswordComponent } from './modal-password/modal-password.component';
import { ModalRemoveTeamComponent } from './modal-remove-team/modal-remove-team.component';
import { ModalAddTeamComponent } from './modal-add-team/modal-add-team.component';
import { ModalTeamNameComponent } from './modal-team-name/modal-team-name.component';
import { ModalRemoveUserComponent } from './modal-remove-user/modal-remove-user.component';
import { ModalCancelInvitationComponent } from './modal-cancel-invitation/modal-cancel-invitation.component';
import { ModalResendInvitationComponent } from './modal-resend-invitation/modal-resend-invitation.component';
import { ModalUpdateWorkspaceComponent } from './modal-update-workspace/modal-update-workspace.component';
import { ModalInviteComponent } from './modal-invite/modal-invite.component';
import { ModalDeactivateComponent } from './modal-deactivate/modal-deactivate.component';
import { ModalReactivateComponent } from './modal-reactivate/modal-reactivate.component';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { ModalChangeAccountComponent } from './modal-change-account/modal-change-account.component';
import { ModalAddAccountComponent } from './modal-add-account/modal-add-account.component';
import { ModalInvitationsComponent } from './modal-invitations/modal-invitations.component';
import { ModalCreateWorkspaceComponent } from './modal-create-workspace/modal-create-workspace.component';
import { ModalSlideComponent } from './modal-slide/modal-slide.component';
import { ModalDeleteMediaComponent } from './modal-delete-media/modal-delete-media.component';
import { ModalDeleteSlideComponent } from './modal-delete-slide/modal-delete-slide.component';
import { ModalSlideImageComponent } from './modal-slide-image/modal-slide-image.component';
import { ModalDeleteGraphicComponent } from './modal-delete-graphic/modal-delete-graphic.component';
import { ModalEditSlideComponent } from './modal-edit-slide/modal-edit-slide.component';
import { ModalGraphicComponent } from './modal-graphic/modal-graphic.component';
import { DataService } from './data-service';
import { NouisliderModule } from 'ng2-nouislider';

@NgModule({
  declarations: [
    ModalTemplateComponent,
    ModalDeleteTemplateComponent,
    ModalCopyTemplateComponent,
    ModalPasswordComponent,
    ModalRemoveTeamComponent,
    ModalRemoveUserComponent,
    ModalAddTeamComponent,
    ModalTeamNameComponent,
    ModalCancelInvitationComponent,
    ModalResendInvitationComponent,
    ModalUpdateWorkspaceComponent,
    ModalInviteComponent,
    ModalDeactivateComponent,
    ModalReactivateComponent,
    ModalDeleteComponent,
    ModalChangeAccountComponent,
    ModalAddAccountComponent,
    ModalInvitationsComponent,
    ModalCreateWorkspaceComponent,
    ModalSlideComponent,
    ModalDeleteMediaComponent,
    ModalDeleteSlideComponent,
    ModalSlideImageComponent,
    ModalDeleteGraphicComponent,
    ModalEditSlideComponent,
    ModalGraphicComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    CommonModule,
    NouisliderModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  providers: [
    DataService,
  ],
  exports: [
    ModalTemplateComponent,
    ModalDeleteTemplateComponent,
    ModalCopyTemplateComponent,
    ModalPasswordComponent,
    ModalRemoveTeamComponent,
    ModalRemoveUserComponent,
    ModalAddTeamComponent,
    ModalTeamNameComponent,
    ModalCancelInvitationComponent,
    ModalResendInvitationComponent,
    ModalUpdateWorkspaceComponent,
    ModalInviteComponent,
    ModalDeactivateComponent,
    ModalReactivateComponent,
    ModalDeleteComponent,
    ModalChangeAccountComponent,
    ModalAddAccountComponent,
    ModalInvitationsComponent,
    ModalCreateWorkspaceComponent,
    ModalSlideComponent,
    ModalDeleteMediaComponent,
    ModalDeleteSlideComponent,
    ModalSlideImageComponent,
    ModalDeleteGraphicComponent,
    ModalEditSlideComponent,
    ModalGraphicComponent
  ],
})
export class UiModalsContainersModule {}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SelectedTemplateService {
  private selectedTemplateNameSource = new BehaviorSubject<string>('');
  selectedTemplateName$ = this.selectedTemplateNameSource.asObservable();

  setSelectedTemplateName(templateName: string){
    this.selectedTemplateNameSource.next(templateName);
  }

  private selectedTemplateIdSource = new BehaviorSubject<number>(0);
  selectedTemplateId$ = this.selectedTemplateIdSource.asObservable();

  setSelectedTemplateId(templateId: number){
    this.selectedTemplateIdSource.next(templateId);
  }
}
<button type="button" class="dropdown-item" data-toggle="modal" data-target=".bd-example-modal-lg-copy" (click)="openModal(template)">Deactivate Account</button>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Change Account Type</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body text-center">
      <h6 class="modal-title mb-4">Do you want to deactivate {{ selectedUserName }}? {{ selectedUserName }} will not be able to
        use Arao anymore. You can reactivate any time.
      </h6>
      <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="confirm()">{{'modal.yes' | translate}}</button> {{" "}}
      <button type="button" class="mb-0 btn btn-outline-primary btn-shadow bold-font margin-5" (click)="decline()">{{'modal.no' | translate}}</button>
    </div>
  </div>
</ng-template>
<h1 class="simple-icon-pencil" (click)="openModal(template)"></h1>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update Workspace Information</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group position-relative error-l-50">
      <form #updateTeamForm="ngForm" novalidate="" class="ng-pristine ng-valid ng-touched">
        <label class="form-group has-float-label">
          <input class="form-control" [(ngModel)]="selectedTeam.team.name" name="name" type="text"/>
          <span class="custom-color">Workspace name</span>
        </label>
        <label class="form-group has-float-label">
          <input class="form-control" [(ngModel)]="selectedTeam.team.domain" name="domain" type="text"/>
          <span class="custom-color">Workspace domain</span>
        </label>
        <label class="custom-color">
          <a class="primary-color primary-color-underline-link" (click) = "fileInput.click()"> Upload new </a>
           workspace logo
        </label>
        <input type="file" class="sr-only" id="inputImage" name="file" accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff" 
          required ngModel #fileInput #logo="ngModel" name="logo" (change)="onFileSelected($event)" (click)="fileInput.click()"> 
          &nbsp;&nbsp;
        <div id="cropperContainer">
          <img [src]="newTeamLogo ? newTeamLogo : 'https://f004.backblazeb2.com/file'+selectedTeam.team.logo" alt="Selected Image" class="list-thumbnail border-0">
        </div> 
      </form>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="updateTeam();">Save changes</button>
      </div>
    </div>
  </div>
</ng-template>

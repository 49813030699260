<label (click)="openModal(template)">My Invitations</label>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">My Invitations</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group position-relative error-l-50">
        <p class="mb-4 custom-color" [hidden]="!hasInvitations">You are invited to be part of the workspace. You can accept, reject or create a new workspace.</p>
        <p class="mb-4 custom-color" [hidden]="hasInvitations">You don't have invitations to join any workspace.</p>
        <div class="d-flex flex-column">
          <div *ngFor="let team of teams" class="d-flex align-items-center m-b-5">
            <img [src]="'https://f004.backblazeb2.com/file' + team.logo" alt="Selected Image" 
                class="list-thumbnail border-0 logo-resize w-75 margin-5 image-fit-contain" *ngIf="team.logo">
            <h6 class="mb-0 w-100">{{ team.name }}</h6>
            <button class="mb-0 btn btn-primary btn-shadow bold-font w-30 margin-5" (click)="acceptInvitation(team.token)">ACCEPT</button>
            <button class="mb-0 btn btn-outline-primary btn-shadow bold-font w-30 margin-5" (click)="rejectInvitation(team.token)">REJECT</button>
          </div>
        </div>
    </div>
  </div>
</ng-template>

import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  {
    icon:'iconsminds-computer',
    label:'menu.templates-page',
    get to(): string {
      const teamId = localStorage.getItem('teamId');
      return `${adminRoot}/${teamId}/templates-page`;
    },
    roles: [UserRole.OWNER, UserRole.EDITOR, UserRole.VIEWER],
  },

  {
    icon:'simple-icon-picture',
    label:'menu.image-menu',
    to: `${adminRoot}/media-page`,
    roles: [UserRole.OWNER],
  },

  {
    icon: 'simple-icon-people',
    label: 'menu.workspace-page',
    get to(): string {
      const teamId = localStorage.getItem('teamId');
      return `${adminRoot}/${teamId}/workspace-page`;
    },
    roles: [UserRole.OWNER, UserRole.EDITOR],
  },

  {
    icon:'simple-icon-people',
    label:'menu.teams-page',
    to: `${adminRoot}/teams-page`,
    roles: [UserRole.OWNER],
  },

  {
    icon:'simple-icon-user',
    label:'menu.account-page',
    to: `${adminRoot}/account-page`,
    roles: [UserRole.OWNER, UserRole.VIEWER, UserRole.EDITOR],
  },
];

export default data;
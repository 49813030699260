import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { SelectedGroupService } from 'src/app/views/app/teams-page/selected-group-service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-remove-team',
  templateUrl: './modal-remove-team.component.html'
})

export class ModalRemoveTeamComponent implements OnInit{
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  private selectedWorkspaceId: number;
  private selectedGroupId: number;
  selectedGroupName: string;

  constructor(private modalService: BsModalService,
    private selectedGroupService: SelectedGroupService, 
    private selectedTeamService: SelectedTeamService,
    private tokenService: TokenService,
    private http: HttpClient, 
    private dataService: DataService,
    private notifications: NotificationsService) { }
  
    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => 
        this.selectedWorkspaceId = workspaceId );
      this.selectedGroupService.selectedGroupId$.subscribe(groupId => 
        this.selectedGroupId = groupId);
      this.selectedGroupService.selectedGroupName$.subscribe(groupName => 
        this.selectedGroupName = groupName);
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void {
    this.http
    .delete(`${environment.apiUrl}/v1/groups/${this.selectedGroupId}?teamId=${this.selectedWorkspaceId}`, { 
      headers: { Authorization: this.authHeader } })
    .subscribe((resData) => { 
      this.modalRef.hide();
      this.dataService.notifyDataUploaded();
      this.notifications.create('Team removed', 'Team successfully removed from the workspace!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
    (error) => {
      this.modalRef.hide();
      this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
    );
  }
}
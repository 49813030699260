import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';

@Injectable({ 
  providedIn: 'root' 
})

export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService, 
    private tokenService: TokenService,
    private router: Router) {}

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const currentUser = await this.authService.getUser();

    if (currentUser) {
      if (route.data && route.data.roles) {
        if (route.data.roles.includes(currentUser.role)) {
          return true;
        } else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/user/login']);
      return false;
    }
  }

  checkLogin(url: string): boolean {
    if (this.tokenService.getRefreshToken()) {
      return true;
    }
    this.authService.redirectUrl = url;
    this.router.navigate(['/login']).then(_ => false);
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    if (localStorage.getItem('access-token')) { // logged in so return true
      return true;
    }
    this.router.navigate(['/user/login']); // not logged in so redirect to login page
    return false;
  }
}

<button type="button" class="btn btn-primary btn-lg top-right-button bold-font btn-shadow mr-1" (click)="openModal(template)">ADD NEW SLIDE</button>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add New Slide</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-row mb-4 align-items-center justify-content-center">
        <div>
          <label class="form-group has-float-label">
            <input class="form-control" required [(ngModel)]="name" name="name" type="text"/>
            <span>Slide name</span>
          </label>
          <label class="form-group has-float-label">
            <input class="form-control" required [(ngModel)]="duration" name="duration" type="text" (input)="sanitizeInput($event)"/>
            <span>Slide duration <i>(in seconds)</i></span>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
          <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="add();" [disabled]="buttonClicked">Add</button>
        </div>
      </div>
  </div>
</ng-template>

<ng-template #templateNested>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add New Slide Image</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal2()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input type="file" #fileInput style="display: none;" required ngModel #logo="ngModel" name="logo" (change)="onFileSelected($event)"> &nbsp;
    <p>You can choose image you have already uploaded in media!</p>
    <p class="text-media-color" [hidden]="hasMedia">&nbsp;&nbsp; You don't have any media yet.</p>
    <div class="image-grid-media">
      <div *ngFor="let media of medias" class="image-container-media">
        <div id="cropperContainerMedia">
          <img class="list-thumbnail-media border-0" [src]="'https://f004.backblazeb2.com/file'+media.url" alt="Card image cap" (click)="addG(media.url)">
        </div>
      </div>
    </div>    
    
    <p>Or 
      <a class="primary-color primary-color-underline-link" accept="image/*" (click)= "fileInput.click();">upload </a>
      image to add it to the slide! &nbsp;
    </p>    
    <br>
    <div id="cropperContainer">
      <img [src]="imageUrl" alt="Selected Image" class="list-thumbnail border-0" *ngIf="imageUrl">
    </div>
    <br>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="upload();" [disabled]="buttonAddClicked">Add</button>
      </div>
    </div>
  </div>   
</ng-template>

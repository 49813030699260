import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SelectedMediaService {
  private selectedMediaIdSource = new BehaviorSubject<number>(0);
  selectedMediaId$ = this.selectedMediaIdSource.asObservable();

  setSelectedMediaId(mediaId: number){
    this.selectedMediaIdSource.next(mediaId);
  }
}
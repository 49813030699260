<button type="button" class="btn btn-primary btn-lg top-right-button btn-shadow bold-font mr-1" (click)="openModal(template)">ADD NEW</button>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Template</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group position-relative error-l-50">
      <label class="form-group has-float-label">
        <input class="form-control" [(ngModel)]="name" name="name" type="text"/>
        <span class="custom-color">Template name</span>
      </label>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="addTemplate();">Add</button>
      </div>
    </div>
  </div>
</ng-template>

import { Component, TemplateRef, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { SelectedTemplateService } from 'src/app/views/app/templates-page/selected-template-service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-slide',
  templateUrl: './modal-slide.component.html',
})

export class ModalSlideComponent implements OnInit {
  private authHeader = this.tokenService.getToken(); 
  buttonClicked = false;
  private selectedWorkspaceId: number;
  private selectedTemplateId: number;
  name: string;
  order: number = 1;
  duration: number;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  @ViewChild('templateNested') templateNested: TemplateRef<any>;
  @ViewChild('fileInput') fileInput: ElementRef;
  medias: { id: number, name: string, url: string, createdAt: number, contentType: string }[];
  selectedSlideId: number;
  logoUrl: string;
  hasMedia: boolean = false;
  buttonAddClicked = false;

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService, 
    private selectedTemplateService: SelectedTemplateService,
    private tokenService: TokenService,
    private http: HttpClient,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => // Get Selected workspaceId
          this.selectedWorkspaceId = workspaceId );
      this.selectedTemplateService.selectedTemplateId$.subscribe(templateId => 
        this.selectedTemplateId = templateId );
      this.getMedia();
    }

    add(){ 
      this.buttonClicked = true;
      this.http
      .post(`${environment.apiUrl}/v1/templates/${this.selectedTemplateId}/slides`, { 
        name: this.name, 
        order: this.order, 
        duration: this.duration,
        transitionIn: '', transitionOut: '', 
        teamId: this.selectedWorkspaceId }, { 
          headers: { Authorization: this.authHeader } })
      .subscribe((resData: any) => { 
        this.selectedSlideId = resData.templateSlideId;
        this.name = '';
        this.order ++;
        this.duration = null;
        this.buttonClicked = false;
        this.openModal2(this.templateNested);
        this.modalRef.hide();
        this.dataService.notifyDataUploaded();
        this.notifications.create('Slide added', 'New slide successfully added!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
      (error) => {
        this.buttonClicked = false;
        this.modalRef.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
      );
    }

    image!: File;
    imageUrl: string;
    onFileSelected(event) { // For Slide Image
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = reader.result as string;
        this.image = <File>event.target.files[0];
      };
    }

    uploadFile(): void { // For Slide Image
      this.fileInput.nativeElement.click();
    }

  addG(mediaUrl: string){ // API Call add image from media to slide
    this.http
    .post(`${environment.apiUrl}/v1/templates/${this.selectedTemplateId}/slides/${this.selectedSlideId}/graphics`, {
      url: mediaUrl, width: 100, height: 150, scale: 25,
      background: 'no', vertical_align: 'v-left', horizontal_align: 'h-left', 
      z_order: 1, teamId: this.selectedWorkspaceId}, { 
        headers:{Authorization: this.authHeader} })
    .subscribe((resData) => {
      this.modalRef2.hide();
      this.dataService.notifyDataUploaded();
      this.notifications.create('Image uploaded', 'Successfully uploaded image to the slide!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}, 
      (error) => (this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })) 
    );
  }

  upload(){ // API Call to Upload to Media
    this.buttonAddClicked = true;
    const formData = new FormData();
    if (!this.image) { // Check if image is selected
        console.error('No image selected!');
        return;
    }
    formData.append('file', this.image, this.image.name);
    this.http
    .post(`${environment.apiUrl}/v1/media/upload?teamId=${this.selectedWorkspaceId}`, formData, {
      headers: { Authorization: this.authHeader }})
    .subscribe((resData: any) => { 
      this.logoUrl = resData.url; 
      this.uploadToSlide(this.logoUrl); },
    (error) => {
      this.buttonAddClicked = false;
      if (error.status === 0) {
        this.notifications.create('Request Entity Too Large Error', 
          'The file you are trying to upload is too large. Please select a smaller file.', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
        this.modalRef.hide();
      } else {
        this.modalRef.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}}
    );    
  }

  uploadToSlide(logoUrl: string){
    this.http
    .post(`${environment.apiUrl}/v1/templates/${this.selectedTemplateId}/slides/${this.selectedSlideId}/graphics`, {
      url: logoUrl, width: 100, height: 150, scale: 25,
      background: 'no', vertical_align: 'v-left', horizontal_align: 'h-left', 
      z_order: 1, teamId: this.selectedWorkspaceId}, { 
        headers:{Authorization: this.authHeader} })
    .subscribe((resData) => {
      this.modalRef2.hide();
      this.buttonAddClicked = false;
      this.dataService.notifyDataUploaded();
      this.notifications.create('Image uploaded', 'Successfully uploaded image to the slide!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}, 
    (error) => {
      this.modalRef2.hide();
      this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
   });
  }

  getMedia(){ // Get images from media
    this.http
    .get(`${environment.apiUrl}/v1/media?teamId=${this.selectedWorkspaceId}`, { headers:{Authorization: this.authHeader} })
    .subscribe((resData: any) => {
      this.hasMedia = Array.isArray(resData) && resData.length > 0;
      this.medias = resData.map(item => ({ 
        id: item.id, 
        name: item.name, 
        url: item.url, 
        createdAt: item.createdAt, 
        contentType: item.contentType }))}, 
      (error) => (this.notifications.create('Error', error.error.message,
      NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })) 
    );
  }

  sanitizeInput(event: any) {
    const input = event.target;
    const sanitizedValue = input.value.replace(/[^0-9]/g, '');
    input.value = sanitizedValue;
  }  

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.modalService.onHidden.subscribe(() => {
      this.name = '';
      this.duration = null;
    });
  }
  openSecondModal() {
    this.modalRef2 = this.modalService.show(this.templateNested);
  }  
  openModal2(template: TemplateRef<any>): void {
    this.modalRef2 = this.modalService.show(template, { class: 'second' });
    this.modalService.onHidden.subscribe(() => {
      this.imageUrl = '';
    });
  }
  closeModal(): void{
    this.modalRef.hide();
    this.name = '';
    this.duration = null;
  }
  closeModal2(): void{
    this.modalRef2.hide();
    this.imageUrl = '';
  }
}

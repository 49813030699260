import { Component, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-password',
  templateUrl: './modal-password.component.html',
})

export class ModalPasswordComponent{
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  passwordsMatch: boolean = true;

  constructor(private modalService: BsModalService, 
    private http: HttpClient, 
    private tokenService: TokenService,
    private notifications: NotificationsService) { }

  openModal(template: TemplateRef<any>): void {
    this.emptyFields();
    this.modalRef = this.modalService.show(template);
  }

  changePassword(){ // API Call to change user password
    if(this.newPassword !== this.confirmPassword) {
      this.passwordsMatch = false; /** Passwords do not match, can't make API Call */
    } else {
      this.passwordsMatch = true; /** Passwords match, make API Call */
      this.http
      .put(`${environment.apiUrl}/v1/profiles/me/password`,{ 
        oldPassword : this.oldPassword,
        newPassword: this.newPassword }, { 
          headers:{Authorization: this.authHeader} } )
      .subscribe((resData) => { 
        this.modalRef.hide();
        this.emptyFields();
        this.notifications.create('Password changed', 'Password successfully changed!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}, 
      (error) => {
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
      });
    }
  }

  emptyFields(){
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
  }
}
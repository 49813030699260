import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { SelectedUserService } from 'src/app/views/app/workspace-page/selected-user-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-resend-invitation',
  templateUrl: './modal-resend-invitation.component.html'
})

export class ModalResendInvitationComponent implements OnInit{
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  private selectedWorkspaceId: number;
  selectedUserToken: string;
  selectedUserEmail: string;

  constructor(private modalService: BsModalService,
    private selectedUserService: SelectedUserService, 
    private selectedTeamService: SelectedTeamService,
    private tokenService: TokenService,
    private http: HttpClient, 
    private notifications: NotificationsService) { }
  
    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId =>  
        this.selectedWorkspaceId = workspaceId );
      this.selectedUserService.selectedUserToken$.subscribe(userToken => 
        this.selectedUserToken = userToken );
      this.selectedUserService.selectedUserEmail$.subscribe(userEmail =>  
        this.selectedUserEmail = userEmail );
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void { 
    this.http
    .put(`${environment.apiUrl}/v1/teams/${this.selectedWorkspaceId}/invitations`, {
      token: this.selectedUserToken, email: this.selectedUserEmail}, { headers: { Authorization: this.authHeader } })
    .subscribe((resData) => { 
      this.modalRef.hide();
      this.notifications.create('Invitation resend', 'Invitation successfully resend to the user!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
    (error) => {
      this.modalRef.hide();
      this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
    );
  }
}

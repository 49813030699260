import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getUserRole } from 'src/app/utils/util';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';

const API_URL = 'https://staging-api.arao.io/user-api';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ 
  providedIn: 'root'
})

export class AuthService {
  redirectUrl = '';

  constructor(private auth: AngularFireAuth, 
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private notifications: NotificationsService) {}

  async getUser() { // tslint:disable-next-line:typedef, get the current user and their role method
    const u = await this.auth.currentUser;
    return { ...u, role: getUserRole() };
  }

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return ('');
  }

  getAuthHeader() : string{
    return this.tokenService.getToken();
  }

  teams: any[] = [];
  login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = { email: loginData.email, password: loginData.password };
    
    return this.http.post<any>(API_URL + '/v1/auth/token', body, HTTP_OPTIONS)
    .pipe(tap((res) => {
      this.tokenService.saveToken(res.accessToken);
      this.tokenService.saveRefreshToken(res.refreshToken);
    }),
    switchMap(() => {
      const authHeader = this.tokenService.getToken();     
      return this.http.get(`${environment.apiUrl}/v1/user-invitations`, { headers: { Authorization: authHeader } })
      .pipe(tap((resData: any) => {
        if (resData.length === 0) {
          let authHeader = this.tokenService.getToken();
          this.http
          .get(`${environment.apiUrl}/v1/users/my-teams`, { headers:{Authorization: authHeader} } )
          .subscribe((resData: any) => { 
            this.teams = resData;
            if (this.teams.length > 0) {
              const firstTeamId = this.teams[0].team.id;
              localStorage.setItem('teamId', JSON.stringify(firstTeamId));
              this.router.navigate(['/app/'+ firstTeamId + '/templates-page']);
            } else if(this.teams.length === 0) {
              this.router.navigate(['/user/create-workspace']);
          }});   
        } else {
          this.router.navigate(['/user/join-workspace']);
       }}),
      catchError((error) => {
        this.notifications.create('Error', error.error.message, NotificationType.Bare, {
          theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        return throwError(error); 
      }));
    }),
    catchError((error) => {
      this.notifications.create('Error', error.error.message, NotificationType.Bare, {
        theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
      return throwError(error); 
      })
    );
  }

  refreshToken(refreshData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = {refreshToken: refreshData };

    return this.http.put<any>(API_URL + '/v1/auth/token', body, HTTP_OPTIONS)
      .pipe(tap(res => {
        this.tokenService.saveToken(res.access_token);
        this.tokenService.saveRefreshToken(res.refreshToken);
      }),
      catchError(AuthService.handleError)
    );
  }

  logout(): void{
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
  }

  register(registerData: any): Observable<any> {
    const body = { firstName: registerData.firstName, lastName: registerData.lastName,
      email: registerData.email, password: registerData.password };

      return this.http.post<any>(API_URL + '/v1/users', body, HTTP_OPTIONS).pipe(
        switchMap((res) => {
          const loginBody = {
            email: registerData.email,
            password: registerData.password
          };
          return this.http.post<any>(API_URL + '/v1/auth/token', loginBody, HTTP_OPTIONS).pipe(
            tap((res) => {
              this.tokenService.saveToken(res.accessToken);
              this.tokenService.saveRefreshToken(res.refreshToken);
            }),
            switchMap(() => {
              const authHeader = this.tokenService.getToken();
              return this.http.get(`${environment.apiUrl}/v1/user-invitations`, { headers: { Authorization: authHeader } })
              .pipe(tap((resData: any) => {
                if (resData.length === 0) {
                  this.router.navigate(['/user/create-workspace']);
                } else {
                  this.router.navigate(['/user/join-workspace']);
                }}),
              catchError((error) => {
                this.notifications.create('Error', error.error.message, NotificationType.Bare, {
                  theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                return throwError(error); 
              }));
            }),
            catchError((error) => {
              this.notifications.create('Error', error.error.message, NotificationType.Bare, {
                theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
              return throwError(error); 
            }));
        }),
        catchError((error) => {
          this.notifications.create('Error', ' User with that email address already exists!',
            NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false }) 
          return throwError(error);
    }));
  }
}
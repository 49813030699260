<h3 class="simple-icon-options-vertical trash-can" (click)="openModal(template)"></h3>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Image position and size</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div style="display: flex; justify-content: center;">
        <img [src]="'https://f004.backblazeb2.com/file' + selectedGraphicUrl" alt="Uploaded Image"
              class="list-thumbnail border-0 graphic-style image-fit-contain">
    </div>
        
    <!-- ALIGNMENT -->
    <form>
        <div class="d-flex align-items-center flex-wrap">

            <div>Position &nbsp;&nbsp; Horizontal</div>&nbsp;&nbsp;
            <div class="custom-control custom-radio">
                <input type="radio" [id]="'radiohLeft'" [name]="'h-alignment'" value="h-left" class="custom-control-input"
                    (change)="onRadioChange()" [(ngModel)]="selectedGraphicHA">
                <label class="custom-control-label" [for]="'radiohLeft'">
                    <img src="../../../../assets/img/alignment/hl.jpg" class="alignment-icons">&nbsp;&nbsp;
                </label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" [id]="'radiohCenter'" name="'h-alignment'" value="h-center" class="custom-control-input" 
                    (change)="onRadioChange()" [(ngModel)]="selectedGraphicHA"> 
                <label class="custom-control-label" [for]="'radiohCenter'">
                    <img src="../../../../assets/img/alignment/hc.jpg" class="alignment-icons">&nbsp;&nbsp;
                </label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" [id]="'radiohRight'" name="'h-alignment'" value="h-right" class="custom-control-input" 
                    (change)="onRadioChange()" [(ngModel)]="selectedGraphicHA">
                <label class="custom-control-label" [for]="'radiohRight'">
                    <img src="../../../../assets/img/alignment/hr.jpg" class="alignment-icons">&nbsp;&nbsp;
                </label>
            </div>  

            <div>Vertical</div>&nbsp;&nbsp;
            <div class="custom-control custom-radio">
                <input type="radio" [id]="'radiovLeft'" name="'v-alignment'" value="v-left" class="custom-control-input" 
                    (change)="onRadioChange()" [(ngModel)]="selectedGraphicVA">
                <label class="custom-control-label" [for]="'radiovLeft'">
                    <img src="../../../../assets/img/alignment/vt.jpg" class="alignment-icons">&nbsp;&nbsp;
                </label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" [id]="'radiovCenter'" name="'v-alignment'" value="v-center" class="custom-control-input" 
                    (change)="onRadioChange()" [(ngModel)]="selectedGraphicVA">
                <label class="custom-control-label" [for]="'radiovCenter'">
                    <img src="../../../../assets/img/alignment/vc.jpg" class="alignment-icons">&nbsp;&nbsp;
                </label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" [id]="'radiovRight'" name="'v-alignment'" value="v-right" class="custom-control-input" 
                    (change)="onRadioChange()" [(ngModel)]="selectedGraphicVA">
                <label class="custom-control-label" [for]="'radiovRight'">
                    <img src="../../../../assets/img/alignment/vb.jpg" class="alignment-icons">&nbsp;&nbsp;
                </label>
            </div> 
        </div>
    </form>
    <!-- SLIDER -->
    <div class="form-group row d-flex flex-wrap">
        <label class="col-sm-4 col-form-label">Image Size</label>
        <div class="col-sm-7">
            <nouislider [(ngModel)]="selectedGraphicSize" [min]="0" [max]="100" [step]="1" (change)="onSliderChange($event)"
                [config]="{ start: [10] }"></nouislider>      
        </div>                                                              
    </div>              
    <!--end graphic-->   

    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <button type="submit" class="btn btn-primary btn-shadow bold-font margin-5 mb-0" (click)="modalRef.hide()">Close</button>
      </div>
    </div>
  </div>
</ng-template>
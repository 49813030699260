import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';
import { Router } from '@angular/router';
import { SelectedGroupService } from 'src/app/views/app/teams-page/selected-group-service';

@Component({
  selector: 'app-modal-add-team',
  templateUrl: './modal-add-team.component.html',
})

export class ModalAddTeamComponent implements OnInit {
  private authHeader = this.tokenService.getToken();
  buttonClicked = false;
  private selectedWorkspaceId: number;
  name: string;
  modalRef: BsModalRef;
  private groupId: number;

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService, 
    private selectedGroupService: SelectedGroupService,
    private tokenService: TokenService,
    private http: HttpClient,
    private router: Router,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => 
        this.selectedWorkspaceId = workspaceId);
    }

    addGroup(){ // API Call add-new-group 
      this.buttonClicked = true;
      this.http
      .post(`${environment.apiUrl}/v1/groups`, { 
        name: this.name, teamId: Number(this.selectedWorkspaceId) }, { headers: { Authorization: this.authHeader } })
      .subscribe((resData: any) => { 
        this.modalRef.hide();
        this.dataService.notifyDataUploaded();
        this.buttonClicked = false;
        this.selectedGroupService.setSelectedGroupName(this.name);
        this.name = '';
        this.groupId = resData.id;
        this.selectedGroupService.setSelectedGroupId(this.groupId);
        this.router.navigate(['/app/team-details-page', this.groupId]);
        this.notifications.create('Team added', 'New team successfully added!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
      (error) => {
        this.buttonClicked = false;
        this.modalRef.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
      });  
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
    this.modalService.onHidden.subscribe(() => 
      this.name = '' );
  }

  closeModal(): void{
    this.modalRef.hide();
    this.name = '';
  }
}
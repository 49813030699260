<h4 (click)="openModal(template)"><a class="simple-icon-pencil"></a></h4>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Slide</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="form-group position-relative error-l-50">
        <label class="form-group has-float-label">
            <input type="text" [(ngModel)]="name" name="name" class="form-control input-group-prepend"/>
            <span>Slide name</span>
        </label>
    </div>     
    <div class="form-group position-relative error-l-50">
        <label class="form-group has-float-label">
            <input type="text" [(ngModel)]="duration" name="duration" class="form-control input-group-prepend" (input)="sanitizeInput($event)"/>
            <span>Slide duration <i>(in seconds)</i></span>
        </label>
    </div>

    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="editSlide()">Save</button>
      </div>
    </div>
  </div>
</ng-template>

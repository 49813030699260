import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { SelectedTemplateService } from 'src/app/views/app/templates-page/selected-template-service';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-delete-template',
  templateUrl: './modal-delete-template.component.html',
})

export class ModalDeleteTemplateComponent implements OnInit{
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  private selectedWorkspaceId: number;
  private selectedTemplateId: number;
  name: string;

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService,
    private selectedTemplateService: SelectedTemplateService, 
    private tokenService: TokenService,
    private http: HttpClient,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId =>  //Get Selected Workspace Id
        this.selectedWorkspaceId = workspaceId );
      this.selectedTemplateService.selectedTemplateId$.subscribe(templateId => 
        this.selectedTemplateId = templateId ); 
    }

    deleteT(){ 
      this.http
      .delete(`${environment.apiUrl}/v1/templates/${this.selectedTemplateId}?teamId=${this.selectedWorkspaceId}`, { 
        headers: { Authorization: this.authHeader } })
      .subscribe((resData) => { 
        this.modalRef.hide();
        this.dataService.notifyDataUploaded();
        this.notifications.create('Template deleted', 'Template successfully deleted!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
      (error) => {
        this.modalRef.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
      });  
    }
    
  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }
}
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  private dataUploadedSubject = new Subject<boolean>();
  dataUploaded$ = this.dataUploadedSubject.asObservable();

  notifyDataUploaded() {
    this.dataUploadedSubject.next(true);
  }
}
<button type="button" class="dropdown-item" data-toggle="modal" data-target=".bd-example-modal-lg-copy" (click)="openModal(template)">Delete Image</button>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Delete Image</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body  text-center">
    <div class="form-group position-relative error-l-50">
      <h6 class="custom-color">Are you sure you want to delete this image?</h6>
    </div>
    <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="deleteT()">{{'modal.yes' | translate}}</button> {{" "}}
    <button type="button" class="mb-0 btn btn-outline-primary btn-shadow bold-font margin-5" (click)="modalRef.hide()">{{'modal.no' | translate}}</button>
  </div>
</ng-template>
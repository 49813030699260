<button type="button" class="btn btn-danger btn-sm mb-1" (click)="openModal(template)">Remove User</button>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Remove User</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body text-center">
      <h6 class="modal-title mb-4">Do you want to remove {{ selectedGroupMemberName }} from {{ selectedGroupName }}?</h6>
      <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="confirm()">{{'modal.yes' | translate}}</button> {{" "}}
      <button type="button" class="mb-0 btn btn-outline-primary btn-shadow bold-font margin-5" (click)="decline()">{{'modal.no' | translate}}</button>
    </div>
  </div>
</ng-template>

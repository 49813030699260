import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { SelectedUserService } from 'src/app/views/app/workspace-page/selected-user-service';
import { SelectedGroupService } from 'src/app/views/app/teams-page/selected-group-service';
import { Subject } from 'rxjs';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-add-account',
  templateUrl: './modal-add-account.component.html',
})

export class ModalAddAccountComponent implements OnInit {
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  private selectedGroupId: number;
  private selectedUserId: number;
  selectedUserName: string;
  groups: { id: number, name: string, teamId: number }[];
  dataChanged = new Subject();
  anyGroups: boolean = false;

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService, 
    private selectedUserService: SelectedUserService, 
    private selectedGroupService: SelectedGroupService,
    private tokenService: TokenService,
    private dataService: DataService,
    private http: HttpClient,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => 
        this.getGroups(workspaceId));
      this.selectedGroupService.selectedGroupId$.subscribe(groupId =>
        this.selectedGroupId = groupId );
      this.selectedUserService.selectedUserId$.subscribe(userId => 
        this.selectedUserId = userId );
      this.selectedUserService.selectedUserName$.subscribe(userName => 
        this.selectedUserName = userName );
    }

    add(){ 
      this.http
      .post(`${environment.apiUrl}/v1/groups/${this.selectedGroupId}/members`, {
        teamMemberId: this.selectedUserId}, { headers:{Authorization: this.authHeader} })
      .subscribe((resData) => {
        this.modalRef.hide();
        this.dataService.notifyDataUploaded();
        this.notifications.create('Team member added', 'Successfully added this member to the team!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}, 
      (error) => {
        this.modalRef.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
      );       
    }

  getGroups(id: number){ // Get groups for Workspace
    this.http
    .get(`${environment.apiUrl}/v1/groups?teamId=${id}`, { headers:{Authorization: this.authHeader} })
    .subscribe((resData: any) => {
      this.anyGroups = Array.isArray(resData) && resData.length > 0;
      this.groups = resData.map(item => ({ id: item.id, name: item.name, teamId: item.teamId }));
    });      
  }

  updateSelectedGroup(groupId: number){
    this.selectedGroupService.setSelectedGroupId(groupId);
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SelectedTeamService {
  private selectedTeamNameSource = new BehaviorSubject<string>('');
  selectedTeamName$ = this.selectedTeamNameSource.asObservable();

  private selectedTeamIdSource = new BehaviorSubject<number>(0);
  selectedTeamId$ = this.selectedTeamIdSource.asObservable();

  private selectedTeamDomainSource = new BehaviorSubject<string>('');
  selectedTeamDomain$ = this.selectedTeamDomainSource.asObservable();

  private selectedTeamLogoSource = new BehaviorSubject<string>('');
  selectedTeamLogo$ = this.selectedTeamLogoSource.asObservable();

  setSelectedTeamName(teamName: string) {
    this.selectedTeamNameSource.next(teamName);
  }

  setSelectedTeamId(teamId: number){
    this.selectedTeamIdSource.next(teamId);
  }

  setSelectedTeamDomain(teamDomain: string){
    this.selectedTeamDomainSource.next(teamDomain);
  }
  
  setSelectedTeamLogo(teamLogo: string){
    this.selectedTeamLogoSource.next(teamLogo);
  }

  private userRoleSource = new BehaviorSubject<string>('');
  userRole$ = this.userRoleSource.asObservable();

  setUserRole(userRole: string){
    this.userRoleSource.next(userRole);
  }
}

import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';
import { SelectedTemplateService } from 'src/app/views/app/templates-page/selected-template-service';
import { SelectedSlideService } from 'src/app/views/app/template-details-page/selected-slide-service';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';

@Component({
  selector: 'app-modal-edit-slide',
  templateUrl: './modal-edit-slide.component.html',
})

export class ModalEditSlideComponent implements OnInit {
    private authHeader = this.tokenService.getToken(); 
    private selectedWorkspaceId: number;
    private selectedTemplateId: number;
    private selectedSlideId: number;
    selectedSlideName: string;
    selectedSlideDuration: number;
    modalRef: BsModalRef;
    name: string;
    order: number;
    duration: number;
    slideDuration: string;

  constructor(private modalService: BsModalService,
    private selectedTemplateService: SelectedTemplateService,
    private selectedSlideService: SelectedSlideService,
    private selectedTeamService: SelectedTeamService,
    private tokenService: TokenService,
    private http: HttpClient,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId =>  // Get Selected workspaceId
            this.selectedWorkspaceId = workspaceId );
      this.selectedTemplateService.selectedTemplateId$.subscribe(templateId => 
          this.selectedTemplateId = templateId );
      this.selectedSlideService.selectedSlideId$.subscribe(slideId => 
        this.selectedSlideId = slideId );
      this.selectedSlideService.selectedSlideName$.subscribe(slideName => {
        this.selectedSlideName = slideName;
        this.name = slideName;
      });
      this.selectedSlideService.selectedSlideDuration$.subscribe(slideDuration => {
        this.selectedSlideDuration = slideDuration;
        this.duration = slideDuration;
      });
    }

  editSlide(){ 
    this.http
    .put(`${environment.apiUrl}/v1/templates/${this.selectedTemplateId}/slides/${this.selectedSlideId}`, { 
      name: this.name, 
      order: this.order, 
      duration: this.duration, 
      transitionIn: '', transitionOut: '', 
      teamId: this.selectedWorkspaceId }, { 
        headers: { Authorization: this.authHeader } })
    .subscribe((resData) => { 
      this.modalRef.hide();
      this.dataService.notifyDataUploaded();
      this.notifications.create('Slide information changed', 'Informations for slide successfully changed!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
    (error) => (
      this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false }))
    );  
  }

  sanitizeInput(event: any) {
    const input = event.target;
    const sanitizedValue = input.value.replace(/[^0-9]/g, '');
    input.value = sanitizedValue;
  }  

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
    this.modalService.onHidden.subscribe(() => {
      this.name = '';
    });
  }

  closeModal(): void{
    this.modalRef.hide();
    this.name = '';
  }
}
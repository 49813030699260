import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';
import { SelectedTemplateService } from 'src/app/views/app/templates-page/selected-template-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-template',
  templateUrl: './modal-template.component.html',
})

export class ModalTemplateComponent implements OnInit {
  private authHeader = this.tokenService.getToken(); 
  private selectedWorkspaceId: number;
  name: string;
  modalRef: BsModalRef;
  templateId: number;

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService, 
    private selectedTemplateService: SelectedTemplateService,
    private tokenService: TokenService,
    private http: HttpClient,
    private router: Router,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => // Get Selected workspaceId
        this.selectedWorkspaceId = workspaceId );
    }

    addTemplate(){ 
      this.http
      .post(`${environment.apiUrl}/v1/templates`, { 
        name: this.name, 
        teamId: Number(this.selectedWorkspaceId) }, { 
          headers: { Authorization: this.authHeader } })
      .subscribe((resData: any) => { 
        this.selectedTemplateService.setSelectedTemplateName(this.name);
        localStorage.setItem('templateName', JSON.stringify(this.name));
        this.name = '';
        this.modalRef.hide();
        this.dataService.notifyDataUploaded();
        this.templateId = resData.id;
        this.selectedTemplateService.setSelectedTemplateId(this.templateId);
        this.router.navigate(['/app/template-details-page', this.templateId]);
        this.notifications.create('Template added', 'New template successfully added!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
      (error) => {
        this.modalRef.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
      );
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
    this.modalService.onHidden.subscribe(() => {
      this.name = '';
    });
  }
  closeModal(): void{
    this.modalRef.hide();
    this.name = '';
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SelectedSlideService {
  private selectedSlideIdSource = new BehaviorSubject<number>(0);
  selectedSlideId$ = this.selectedSlideIdSource.asObservable();

  setSelectedSlideId(slideId: number){
    this.selectedSlideIdSource.next(slideId);
  }

  private selectedSlideNameSource = new BehaviorSubject<string>('');
  selectedSlideName$ = this.selectedSlideNameSource.asObservable();

  setSelectedSlideName(slideName: string){
    this.selectedSlideNameSource.next(slideName);
  }

  private selectedSlideDurationSource = new BehaviorSubject<number>(0);
  selectedSlideDuration$ = this.selectedSlideDurationSource.asObservable();

  setSelectedSlideDuration(slideDuration: number){
    this.selectedSlideDurationSource.next(slideDuration);
  }
}
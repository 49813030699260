import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { SelectedTemplateService } from 'src/app/views/app/templates-page/selected-template-service';
import { TokenService } from 'src/app/shared/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-copy-template',
  templateUrl: './modal-copy-template.component.html',
})

export class ModalCopyTemplateComponent implements OnInit{
  private authHeader = this.tokenService.getToken();
  modalRef: BsModalRef;
  private selectedTemplateId: number;
  name: string;
  templateId: number;

  constructor(private modalService: BsModalService,
    private selectedTemplateService: SelectedTemplateService,
    private tokenService: TokenService,
    private http: HttpClient,
    private router: Router,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTemplateService.selectedTemplateId$.subscribe(templateId => //Get Selected Template Id
        this.selectedTemplateId = templateId ); 
    }

    copy(){
      this.http
      .post(`${environment.apiUrl}/v1/templates/${this.selectedTemplateId}/copy`, { 
        name: this.name }, { headers: { Authorization: this.authHeader } })
      .subscribe((resData: any) => { 
        this.selectedTemplateService.setSelectedTemplateName(this.name);
        this.name = '';
        this.modalRef.hide();
        this.dataService.notifyDataUploaded();
        this.templateId = resData.id;
        this.selectedTemplateService.setSelectedTemplateId(this.templateId);
        this.router.navigate(['/app/template-details-page', this.templateId]);
        this.notifications.create('Template copied', 'Template successfully cppied!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
      (error) => {
        this.modalRef.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
      });    
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
    this.modalService.onHidden.subscribe(() => 
      this.name = '' );
  }
  closeModal(): void{
    this.modalRef.hide();
    this.name = '';
  }
}

<button type="button" class="btn btn-outline-primary float-left btn-sm top-right-button btn-shadow bold-font mr-1" (click)="openModal(template)"> Change password </button>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Change Password</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group position-relative error-l-50">
        <label class="form-group has-float-label">
            <input class="form-control" [(ngModel)]="oldPassword" name="oldPassword" type="password"/>
            <span class="custom-color">Old password</span>
        </label>
        <label class="form-group has-float-label">
            <input class="form-control" [(ngModel)]="newPassword" name="newPassword" type="password"/>
            <span class="custom-color">New Password</span>
        </label>
        <label class="form-group has-float-label">
          <input class="form-control" [(ngModel)]="confirmPassword" name="confirmPassword" type="password"/>
          <span class="custom-color">Confirm password</span>
      </label>
      <div *ngIf="!passwordsMatch" class="invalid-tooltip">Passwords do not match.</div>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="changePassword();">Change password</button>
      </div>
    </div>
  </div>
</ng-template>
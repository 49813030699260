import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { SelectedUserService } from 'src/app/views/app/workspace-page/selected-user-service';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-change-account',
  templateUrl: './modal-change-account.component.html',
})

export class ModalChangeAccountComponent implements OnInit {
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  private selectedUserId: number;
  private selectedWorkspaceId: number;
  selectedUserName: string;
  selectedUserRole: string;
  userRole: number = 0;
  roles = ['OWNER', 'EDITOR', 'VIEWER'];

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService, 
    private selectedUserService: SelectedUserService, 
    private tokenService: TokenService,
    private http: HttpClient,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => 
        this.selectedWorkspaceId = workspaceId );
      this.selectedUserService.selectedUserName$.subscribe(userName => 
        this.selectedUserName = userName );
      this.selectedUserService.selectedUserRole$.subscribe(userRole => 
        this.selectedUserRole = userRole );
      this.selectedUserService.selectedUserId$.subscribe(userId =>  // Get Selected userId
        this.selectedUserId = userId );
      if(this.selectedUserRole === 'OWNER'){
        this.userRole=0;
      } else if (this.selectedUserRole === 'EDITOR') {
        this.userRole = 1;
      } else if (this.selectedUserRole === 'VIEWER') {
        this.userRole = 2; }
    }

    change(){ 
      this.http
      .put(`${environment.apiUrl}/v1/teams/${this.selectedWorkspaceId}/members/${this.selectedUserId}`, { 
        userRole: this.roles[this.userRole], userStatus: "ACTIVE" }, { headers: { Authorization: this.authHeader } })
      .subscribe((resData) => {  
        this.modalRef2.hide();
        this.dataService.notifyDataUploaded();
        this.notifications.create('Account type changed', 'User role successfully changed!', 
         NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
      (error) => {
        this.modalRef2.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
      );    
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }
  openModal2(template: TemplateRef<any>): void {
    this.modalRef2 = this.modalService.show(template, { class: 'second' });
  }
}

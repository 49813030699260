import { Component, TemplateRef, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { v4 as uuidv4 } from 'uuid';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-modal-update-workspace',
  templateUrl: './modal-update-workspace.component.html',
})

export class ModalUpdateWorkspaceComponent implements OnInit {
  private authHeader = this.tokenService.getToken();
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('updateTeamForm') updateTeamForm: NgForm;
  selectedWorkspaceId: number;
  selectedTeamName: string;
  selectedTeamDomain: string;
  selectedTeamLogo: string;
  name: string;
  domain: string;
  logoUrl: string;
  modalRef: BsModalRef;
  teamLogoUrl: string;
  storageId: string;
  image!: File;

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService, 
    private tokenService: TokenService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.route.params.subscribe();
      this.selectedWorkspaceId = Number(this.route.snapshot.paramMap.get('teamId'));
      this.selectedTeamService.setSelectedTeamId(this.selectedWorkspaceId);
      this.selectedTeamService.selectedTeamName$.subscribe(teamName => 
        this.selectedTeamName = teamName );
      this.selectedTeamService.selectedTeamDomain$.subscribe(teamDomain => 
        this.selectedTeamDomain = teamDomain );
      this.selectedTeamService.selectedTeamLogo$.subscribe(teamLogo => 
        this.selectedTeamLogo = teamLogo );
      this.listMyTeams();
    }

    teams: any[] = [];
    selectedTeam: any;
    listMyTeams(): void{ // API Call to get all the teams for user
      this.http
      .get(`${environment.apiUrl}/v1/users/my-teams`, { headers:{Authorization: this.authHeader} } )
      .subscribe((resData: any) => { 
        this.teams = resData;
        this.selectedTeam = this.teams.find(team => team.team.id === this.selectedWorkspaceId)}
      );
    }

  newTeamLogo: string;
  onFileSelected(event) { // For Team Logo
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.newTeamLogo = reader.result as string;
      this.image = <File>event.target.files[0];
      const formData = new FormData();
      formData.append('logo', this.image, this.image.name);
      this.upload(formData);
    };    
  }
  
  upload(formData : FormData){
    const storageId = uuidv4(); 
    this.storageId = storageId;
    this.http
    .post(`${environment.apiUrl}/v1/teams/${storageId}/upload`, formData, { headers: { Authorization: this.authHeader }})
    .subscribe((resData: any) => { 
      this.logoUrl = resData.url;
      this.uploadAPICall(this.logoUrl); },
    (error) => {  
      this.newTeamLogo = null;
      if (error.status === 0) {
        this.modalRef.hide();
        this.notifications.create('Request Entity Too Large Error', 'The file you are trying to upload is too large. Please select a smaller file.', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
      } else {
        this.modalRef.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}}
    );  
  }

  uploadAPICall(logoUrl: string){
    this.http
    .put(`${environment.apiUrl}/v1/teams/${this.selectedWorkspaceId}`, {
      name: this.selectedTeam.team.name, 
      domain:this.selectedTeam.team.domain, 
      logo: logoUrl}, { 
        headers: { Authorization: this.authHeader } })
    .subscribe((resData) => { 
      this.dataService.notifyDataUploaded(); },
    (error) => {
      this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
    );
  }

  uploadFile(): void { // For Team Logo
    this.fileInput.nativeElement.click();
  }

  updateTeam(){ 
    this.http
    .put(`${environment.apiUrl}/v1/teams/${this.selectedWorkspaceId}`, {
      name: this.selectedTeam.team.name, 
      domain:this.selectedTeam.team.domain, 
      logo: this.selectedTeam.team.logo }, { 
        headers: { Authorization: this.authHeader } })
    .subscribe((resData) => { 
      this.modalRef.hide();
      this.dataService.notifyDataUploaded();
      this.notifications.create('Workspace information updated', 'Workspace information successfully updated!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
    (error) => {
      this.modalRef.hide();
      this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
      );
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }
}

import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';
import { SelectedTemplateService } from 'src/app/views/app/templates-page/selected-template-service';
import { SelectedSlideService } from 'src/app/views/app/template-details-page/selected-slide-service';
import { SelectedGraphicService } from 'src/app/views/app/template-details-page/selected-graphic-service';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';

@Component({
  selector: 'app-modal-graphic',
  templateUrl: './modal-graphic.component.html',
})

export class ModalGraphicComponent implements OnInit{
  private authHeader = this.tokenService.getToken();
  modalRef: BsModalRef;
  private selectedWorkspaceId: number;
  private selectedSlideId: number;
  private selectedTemplateId: number;
  private selectedGraphicId: number;
  selectedGraphicUrl: string;
  selectedGraphicHA: string;
  selectedGraphicVA: string;
  selectedGraphicSize: number;

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService,
    private selectedTemplateService: SelectedTemplateService,
    private selectedSlideService: SelectedSlideService,
    private selectedGraphicService: SelectedGraphicService,
    private tokenService: TokenService,
    private http: HttpClient,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => 
        this.selectedWorkspaceId = workspaceId );
      this.selectedTemplateService.selectedTemplateId$.subscribe(templateId => 
        this.selectedTemplateId = templateId );
      this.selectedSlideService.selectedSlideId$.subscribe(slideId => 
        this.selectedSlideId = slideId );
      this.selectedGraphicService.selectedGraphicId$.subscribe(graphicsId =>
        this.selectedGraphicId = graphicsId );
      this.selectedGraphicService.selectedGraphicUrl$.subscribe(graphicUrl => 
        this.selectedGraphicUrl = graphicUrl );
      this.selectedGraphicService.selectedGraphicHA$.subscribe(graphicHA => 
        this.selectedGraphicHA = graphicHA );
      this.selectedGraphicService.selectedGraphicVA$.subscribe(graphicVA => 
        this.selectedGraphicVA = graphicVA );
      this.selectedGraphicService.selectedGraphicSize$.subscribe(graphicSize => 
        this.selectedGraphicSize = graphicSize );
    }

  sliderValue: number = 100;
  logoHeight: number = 100;
  imageWidth: number = 100;
  imageScale: number = 25;
  onSliderChange(event) {
    this.imageScale = event;
    this.http
    .put(`${environment.apiUrl}/v1/templates/${this.selectedTemplateId}/slides/${this.selectedSlideId}/graphics/${this.selectedGraphicId}`, { 
      url: this.selectedGraphicUrl, 
      width: this.imageScale, height: this.logoHeight, 
      scale: this.imageScale, background: 'null', 
      vertical_align: this.selectedGraphicVA, horizontal_align: this.selectedGraphicHA, 
      z_order: 0, teamId: this.selectedWorkspaceId }, { 
        headers:{Authorization: this.authHeader} })
    .subscribe((resData) => {
      this.dataService.notifyDataUploaded();
      this.notifications.create('Slide graphics changed', 'Successfully changed slide graphics!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
    );
  }

  onRadioChange(){
    this.http
    .put(`${environment.apiUrl}/v1/templates/${this.selectedTemplateId}/slides/${this.selectedSlideId}/graphics/${this.selectedGraphicId}`, { 
      url: this.selectedGraphicUrl, width: this.imageWidth,  height: this.logoHeight, 
      scale: this.imageScale, background: 'null', 
      vertical_align: this.selectedGraphicVA, horizontal_align: this.selectedGraphicHA, 
      z_order: 0, teamId: this.selectedWorkspaceId }, { 
        headers:{Authorization: this.authHeader} })
    .subscribe((resData) => {
      this.dataService.notifyDataUploaded();
      this.notifications.create('Slide graphics changed', 'Successfully changed slide graphics!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
    );  
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SelectedGroupService {
  private selectedGroupIdSource = new BehaviorSubject<number>(0);
  selectedGroupId$ = this.selectedGroupIdSource.asObservable();

  setSelectedGroupId(groupId: number){
    this.selectedGroupIdSource.next(groupId);
  }

  private selectedGroupNameSource = new BehaviorSubject<string>('');
  selectedGroupName$ = this.selectedGroupNameSource.asObservable();

  setSelectedGroupName(groupName: string){
    this.selectedGroupNameSource.next(groupName);
  }

  private selectedGroupMemberIdSource = new BehaviorSubject<number>(0);
  selectedGroupMemberId$ = this.selectedGroupMemberIdSource.asObservable();

  setSelectedGroupMemberId(groupMemberId: number){
    this.selectedGroupMemberIdSource.next(groupMemberId);
  }

  private selectedGroupMemberNameSource = new BehaviorSubject<string>('');
  selectedGroupMemberName$ = this.selectedGroupMemberNameSource.asObservable();

  setSelectedGroupMemberName(groupMemberName: string){
    this.selectedGroupMemberNameSource.next(groupMemberName);
  }
}
import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { SelectedUserService } from 'src/app/views/app/workspace-page/selected-user-service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html'
})

export class ModalDeleteComponent implements OnInit{
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  private selectedUserId: number;
  private selectedWorkspaceId: number;
  selectedUserName: string;
  selectedTeamName: string;

  constructor(private modalService: BsModalService,
    private selectedUserService: SelectedUserService,
    private selectedTeamService: SelectedTeamService,
    private tokenService: TokenService,
    private http: HttpClient, 
    private dataService: DataService,
    private notifications: NotificationsService) { }
  
    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => 
        this.selectedWorkspaceId = workspaceId );
      this.selectedTeamService.selectedTeamName$.subscribe(teamName => 
        this.selectedTeamName = teamName );
      this.selectedUserService.selectedUserId$.subscribe(userId => 
        this.selectedUserId = userId );
      this.selectedUserService.selectedUserName$.subscribe(userName => 
        this.selectedUserName = userName );
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void { 
    this.http
    .delete(`${environment.apiUrl}/v1/teams/${this.selectedWorkspaceId}/members/${this.selectedUserId}`, { 
      headers: { Authorization: this.authHeader } })
    .subscribe((resData) => {  
      this.modalRef.hide();
      this.dataService.notifyDataUploaded();
      this.notifications.create('User deleted', 'User successfully deleted from this workspace!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
    (error) => {
      this.modalRef.hide();
      this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
    });  
  }
}

import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';
import { SelectedTemplateService } from 'src/app/views/app/templates-page/selected-template-service';
import { SelectedSlideService } from 'src/app/views/app/template-details-page/selected-slide-service';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';

@Component({
  selector: 'app-modal-delete-slide',
  templateUrl: './modal-delete-slide.component.html',
})

export class ModalDeleteSlideComponent implements OnInit{
  private authHeader = this.tokenService.getToken();
  modalRef: BsModalRef;
  private selectedTemplateId: number;
  name: string;
  private selectedSlideId: number;
  private selectedWorkspaceId: number;

  constructor(private modalService: BsModalService,
    private selectedTemplateService: SelectedTemplateService,
    private selectedSlideService: SelectedSlideService,
    private selectedTeamService: SelectedTeamService,
    private tokenService: TokenService,
    private http: HttpClient,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTemplateService.selectedTemplateId$.subscribe(templateId =>  //Get Selected Template Id
        this.selectedTemplateId = templateId );  
      this.selectedSlideService.selectedSlideId$.subscribe(slideId =>  // Get Selected Slide Id
        this.selectedSlideId = slideId );
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => 
        this.selectedWorkspaceId = workspaceId );
    }

    deleteT(){ 
      this.http
      .delete(`${environment.apiUrl}/v1/templates/${this.selectedTemplateId}/slides/${this.selectedSlideId}?teamId=${this.selectedWorkspaceId}`, { 
        headers:{Authorization: this.authHeader} })
      .subscribe((resData) => {
        this.modalRef.hide();
        this.dataService.notifyDataUploaded();
        this.notifications.create('Slide deleted', 'Successfully deleted this slide from the template!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}, 
      (error) => {
        this.modalRef.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
      });  
    }
    
  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }
}
import { Component, TemplateRef, ViewChild, ElementRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { v4 as uuidv4 } from 'uuid';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-create-workspace',
  templateUrl: './modal-create-workspace.component.html',
})

export class ModalCreateWorkspaceComponent{
  private authHeader = this.tokenService.getToken(); 
  @ViewChild('fileInput') fileInput: ElementRef;
  modalRef: BsModalRef;
  teamLogoUrl: string;
  logoUrl: string;
  storageId: string;
  name: string; 
  domain: string;

  constructor( private modalService: BsModalService,
    private tokenService: TokenService,
    private http: HttpClient,
    private dataService: DataService,
    private notifications: NotificationsService ) { }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
    this.emptyFields();
  }
  closeModal(): void{
    this.modalRef.hide();
    this.emptyFields();
  }

  emptyFields(){
    this.name = '';
    this.domain = '';
    this.logoUrl = '';
    this.teamLogoUrl = null;
  }

  image!: File;
  imageSize = new Image();
  onFileSelected(event) { // For Team Logo
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.teamLogoUrl = reader.result as string;
      this.image = <File>event.target.files[0];
      this.imageSize = file;
      this.getImageSize(file);
    };
  }

  workspaceLogoW: number;
  workspaceLogoH: number;
  getImageSize(file) { // Check logo width and height
    const image = new Image();
    image.onload = () => {
      this.workspaceLogoW = image.width;
      this.workspaceLogoH = image.height;
    };
    image.src = URL.createObjectURL(file);
  }

    uploadFile(): void { // For Team Logo
      this.fileInput.nativeElement.click();
    }

    create(){ // API Call to Upload Team Logo
      const formData = new FormData();
      if (!this.image) { // Check if image is selected
          console.error('No image selected!');
          return;
      }
      formData.append('logo', this.image, this.image.name);
      const storageId = uuidv4(); 
      this.storageId = storageId;
      this.http
      .post(`${environment.apiUrl}/v1/teams/${storageId}/upload`, formData, {
        headers: { Authorization: this.authHeader }})
      .subscribe((resData: any) => { 
        this.logoUrl = resData.url;
        this.createWorkspaceAPI();},
      (error) => {
        if (error.status === 0) {
          this.notifications.create('Request Entity Too Large Error', 
           'The file you are trying to upload is too large. Please select a smaller file.', 
            NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
          this.modalRef.hide();
        } else {
          this.notifications.create('Error', error.error.message,
            NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
          this.modalRef.hide();
     }});      
    }

    createWorkspaceAPI(){
      this.http
      .post(`${environment.apiUrl}/v1/teams`, { 
        name : this.name, 
        domain: this.domain, 
        logo: this.logoUrl, 
        storageId: this.storageId,
        logoWidth: this.workspaceLogoW,
        logoHeight: this.workspaceLogoH } , { 
          headers: { Authorization: this.authHeader }})
      .subscribe((resData) => { 
        this.emptyFields();
        this.modalRef.hide();
        this.dataService.notifyDataUploaded();
        this.notifications.create('Create workspace', 'Workspace successfully created!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
      (error) => (
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })));
    }
}
<button type="button" class="btn btn-primary btn-lg top-right-button btn-shadow bold-font mr-1" (click)="openModal(template)">INVITE</button><ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Invite new Workspace member</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group position-relative error-l-50">
      <label class="form-group has-float-label">
        <input class="form-control" [(ngModel)]="email" name="email" type="text"/>
        <span class="custom-color">Email</span>
      </label>
      <div>
        <div class="custom-control custom-radio">
            <input type="radio" id="jQueryCustomRadio1" name="jQueryCustomRadio" class="custom-control-input" [(ngModel)]="role" [value]="0">
            <label class="custom-control-label custom-color" for="jQueryCustomRadio1">Owner</label>
        </div>
        <div class="custom-control custom-radio">
            <input type="radio" id="jQueryCustomRadio2" name="jQueryCustomRadio" class="custom-control-input" [(ngModel)]="role" [value]="1">
            <label class="custom-control-label custom-color" for="jQueryCustomRadio2">Editor</label>
        </div>
        <div class="custom-control custom-radio">
            <input type="radio" id="jQueryCustomRadio3" name="jQueryCustomRadio" class="custom-control-input" [(ngModel)]="role" [value]="2">
            <label class="custom-control-label custom-color" for="jQueryCustomRadio3">Viewer</label>
        </div>
      </div>
    </div>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-end">
      <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="invite();"
      [disabled]="!(email && role !== '-1' && !buttonClicked)">Invite</button>
    </div>
  </div>
  </div>
</ng-template>

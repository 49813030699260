<button type="button" class="dropdown-item" data-toggle="modal" data-target=".bd-example-modal-lg-copy" (click)="openModal(template)">Add To Team</button>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Account to Team</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [hidden]="!anyGroups">
    <div class="form-group position-relative error-l-50">
        <h6 class="custom-color">Choose the team you want to add {{ selectedUserName }} to?</h6>
        <div class="custom-control custom-radio" *ngFor="let group of groups" (click)="updateSelectedGroup(group.id);">
            <input type="radio" [id]="'customRadio' + group.id" [name]="'groupRadio'" class="custom-control-input">
            <label [for]="'customRadio' + group.id" class="custom-control-label custom-color">{{ group.name }}</label>
        </div>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="add();">Add</button>
      </div>
    </div>
  </div>
  <div class="modal-body" [hidden]="anyGroups">
    <div class="form-group position-relative error-l-50">
        <h6 class="custom-color">There are no groups to add {{ selectedUserName }} to.</h6>
        <h6>
          <a class="primary-color-underline-link" routerLink="/app/groups-page" (click)="modalRef.hide()">
            Click here
          </a> to create new group.
        </h6>
    </div>
  </div>
</ng-template>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '../shared/auth.guard';
import { UserRole } from '../shared/auth.roles';

const teamId = localStorage.getItem('teamId');
const adminRoot = environment.adminRoot.substr(1); // path cannot start with a slash
const loginRoot = environment.landingRoot.substr(1);
let loggedIn = false;
const authToken = localStorage.getItem('access-token');
const tokenExpiration = localStorage.getItem('access-token_expiration');

if(authToken != null){
  const expirationDate = new Date(parseInt(tokenExpiration, 10));
  const currentDate = new Date();
  if (currentDate < expirationDate) {
    loggedIn = true;
  } else {
    loggedIn = false;
  }
} else {
  loggedIn = false;
}

let routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: loggedIn ? `${adminRoot}/${teamId}/templates-page` : loginRoot },
  {
    path: adminRoot,
    loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    data: { roles: [UserRole.OWNER, UserRole.EDITOR, UserRole.VIEWER] },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/error' },
];

if (!environment.isAuthGuardActive) {
  routes = [
    {
      path: '',
      component: HomeComponent,
      pathMatch: 'full',
    },
    {
      path: 'app',
      loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    },
    {
      path: 'user',
      loadChildren: () =>
        import('./user/user.module').then((m) => m.UserModule),
    },
    { path: 'error', component: ErrorComponent },
    { path: '**', redirectTo: '/error' },
  ];
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class ViewRoutingModule {}

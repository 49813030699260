import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { SelectedUserService } from 'src/app/views/app/workspace-page/selected-user-service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-deactivate',
  templateUrl: './modal-deactivate.component.html'
})

export class ModalDeactivateComponent implements OnInit{
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  private selectedUserId: number;
  private selectedWorkspaceId: number;
  selectedUserName: string;
  selectedUserRole: string;

  constructor(private modalService: BsModalService,
    private selectedUserService: SelectedUserService,
    private selectedTeamService: SelectedTeamService,
    private tokenService: TokenService,
    private http: HttpClient, 
    private dataService: DataService,
    private notifications: NotificationsService) { }
  
    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => 
        this.selectedWorkspaceId = workspaceId );
      this.selectedUserService.selectedUserId$.subscribe(userId => 
        this.selectedUserId = userId );
      this.selectedUserService.selectedUserName$.subscribe(userName => 
        this.selectedUserName = userName );
      this.selectedUserService.selectedUserRole$.subscribe(userRole => 
        this.selectedUserRole = userRole );
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void { 
    this.http
    .put(`${environment.apiUrl}/v1/teams/${this.selectedWorkspaceId}/members/${this.selectedUserId}`, { 
      userStatus: "DEACTIVATED", userRole: this.selectedUserRole }, { headers: { Authorization: this.authHeader } })
    .subscribe((resData) => { 
      this.modalRef.hide();
      this.dataService.notifyDataUploaded();
      this.notifications.create('Deactivate User', 'User successfully deactivated!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
    (error) => {
      this.modalRef.hide();
      this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
    });  
  }
}

<h3 class="simple-icon-trash trash-can" (click)="openModal(template)"></h3>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Delete Image</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group position-relative error-l-50">
        <h6 class="custom-color">Are you sure you want to delete this image from the slide?</h6>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <button type="submit" class="btn btn-primary btn-shadow bold-font margin-5 mb-0" (click)="deleteG()">Delete</button>
      </div>
    </div>
  </div>
</ng-template>
<label (click)="openModal(template)">Create Workspace</label>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Workspace</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group position-relative error-l-50">
      <label class="form-group has-float-label">
        <input class="form-control" [(ngModel)]="name" name="name" type="text"/>
        <span class="custom-color">Workspace name</span>
      </label>
      <label class="form-group has-float-label">
        <input class="form-control" [(ngModel)]="domain" name="domain" type="text"/>
        <span class="custom-color">Workspace domain</span>
      </label>
      <br>
      <label  class="custom-color">
        <a class="primary-color primary-color-underline-link top-right-button" (click) = "fileInput.click();"> Upload </a>
        workspace logo</label>
        <input type="file" #fileInput style="display: none;" required ngModel #logo="ngModel" name="logo" (change)="onFileSelected($event)"> &nbsp;
        &nbsp;
        <div id="cropperContainer">
            <img [src]="teamLogoUrl" alt="Selected Image" class="list-thumbnail border-0" *ngIf="teamLogoUrl">
        </div>
  </div>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-end">
      <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font w-30 margin-5" (click)="create()">CREATE</button>
    </div>
  </div>
  </div>
</ng-template>
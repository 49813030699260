import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modal-invite',
  templateUrl: './modal-invite.component.html',
})

export class ModalInviteComponent implements OnInit {
  private authHeader = this.tokenService.getToken();
  buttonClicked = false;
  selectedWorkspaceId: number;
  email: string;
  role: string = "-1";
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService, 
    private tokenService: TokenService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.route.params.subscribe();
      this.selectedWorkspaceId = Number(this.route.snapshot.paramMap.get('teamId'));
      this.selectedTeamService.setSelectedTeamId(this.selectedWorkspaceId);
    }

    invite(){ 
      this.buttonClicked = true;
      this.http
      .post(`${environment.apiUrl}/v1/teams/${this.selectedWorkspaceId}/invitations`, { 
        email: this.email, role: Number(this.role) }, { headers: { Authorization: this.authHeader } })
      .subscribe((resData) => { 
        this.modalRef.hide();
        this.emptyFields();
        this.buttonClicked = false;
        this.dataService.notifyDataUploaded();
        this.notifications.create('New member invited', 'New member successfully invited to the workspace!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
      (error) => {
        this.modalRef.hide();
        this.buttonClicked = false;
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
      });      
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
    this.modalService.onHidden.subscribe(() => {
      this.emptyFields();
    });
  }
  
  closeModal(): void{
    this.modalRef.hide();
    this.emptyFields(); 
  }

  emptyFields(){
    this.email = '';
    this.role = null;
  }
}

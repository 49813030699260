<button type="button" class="dropdown-item" data-toggle="modal" data-target=".bd-example-modal-lg-copy" (click)="openModal(template)">Change Account Type</button>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Change Account Type</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group position-relative error-l-50">
      <div>
        <h6>Account &nbsp;&nbsp; {{ selectedUserName }}</h6>
       
        <div  style="display: inline-flex;">
          <p> Account type &nbsp;&nbsp;&nbsp;</p>
          <div class="custom-control custom-radio">
            <input type="radio" id="jQueryCustomRadio1" name="jQueryCustomRadio" class="custom-control-input" 
            [(ngModel)]="userRole" [value]="0">
            <label class="custom-control-label custom-color" for="jQueryCustomRadio1">Owner</label> &nbsp;&nbsp;&nbsp;
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" id="jQueryCustomRadio2" name="jQueryCustomRadio" class="custom-control-input" 
            [(ngModel)]="userRole" [value]="1">
            <label class="custom-control-label custom-color" for="jQueryCustomRadio2">Editor</label> &nbsp;&nbsp;&nbsp;
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" id="jQueryCustomRadio3" name="jQueryCustomRadio" class="custom-control-input" 
            [(ngModel)]="userRole" [value]="2">
            <label class="custom-control-label custom-color" for="jQueryCustomRadio3">Viewer</label> &nbsp;&nbsp;&nbsp;
          </div>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-end">
      <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="openModal2(templateNested); modalRef.hide()">Change</button>
    </div>
  </div>
  </div>
</ng-template>

<ng-template #templateNested>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Change Account Type</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <h6>Are you sure you want to change {{selectedUserName}} role?</h6>
    <button type="button" class="mb-0 btn btn-primary btn-shadow bold-font margin-5" (click)="change()">{{'modal.yes' | translate}}</button> {{" "}}
    <button type="button" class="mb-0 btn btn-outline-primary btn-shadow bold-font margin-5" (click)="modalRef2.hide()">{{'modal.no' | translate}}</button>
  </div>
</ng-template>
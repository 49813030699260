import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SelectedUserService {
  private selectedUserNameSource = new BehaviorSubject<string>('');
  selectedUserName$ = this.selectedUserNameSource.asObservable();

  setSelectedUserName(userName: string){
    this.selectedUserNameSource.next(userName);
  }

  private selectedUserRoleSource = new BehaviorSubject<string>('');
  selectedUserRole$ = this.selectedUserRoleSource.asObservable();

  setSelectedUserRole(userRole: string){
    this.selectedUserRoleSource.next(userRole);
  }

  private selectedUserIdSource = new BehaviorSubject<number>(null);
  selectedUserId$ = this.selectedUserIdSource.asObservable();

  setSelectedUserId(userId: number){
    this.selectedUserIdSource.next(userId);
  }

  private selectedUserTokenSource = new BehaviorSubject<string>('');
  selectedUserToken$ = this.selectedUserTokenSource.asObservable();

  setSelectedUserToken(userToken: string){
    this.selectedUserTokenSource.next(userToken);
  }

  private selectedUserEmailSource = new BehaviorSubject<string>('');
  selectedUserEmail$ = this.selectedUserEmailSource.asObservable();

  setSelectedUserEmail(userEmail: string){
    this.selectedUserEmailSource.next(userEmail);
  }
}
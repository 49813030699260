import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { TokenService } from 'src/app/shared/token.service';
import { SelectedMediaService } from 'src/app/views/app/media-page/selected-media-service';

@Component({
  selector: 'app-modal-delete-media',
  templateUrl: './modal-delete-media.component.html',
})

export class ModalDeleteMediaComponent implements OnInit{
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  private selectedWorkspaceId: number;
  private selectedMediaId: number;
  name: string;

  constructor(private modalService: BsModalService,
    private selectedTeamService: SelectedTeamService,
    private selectedMediaService: SelectedMediaService,
    private tokenService: TokenService,
    private http: HttpClient,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId => //Get Selected Workspace Id
        this.selectedWorkspaceId = workspaceId );
      this.selectedMediaService.selectedMediaId$.subscribe(mediaId =>  // Get Selected Media Id
        this.selectedMediaId = mediaId );
    }

    deleteT(){ 
      this.http
      .delete(`${environment.apiUrl}/v1/media/${this.selectedMediaId}?teamId=${this.selectedWorkspaceId}`, { 
        headers: { Authorization: this.authHeader } })
      .subscribe((resData) => { 
        this.modalRef.hide();
        this.dataService.notifyDataUploaded();
        this.notifications.create('Media deleted', 'Media successfully deleted!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
      (error) => {
        this.modalRef.hide();
        this.notifications.create('Error', error.error.message,
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })
      });    
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }
}
import { Injectable } from '@angular/core';

const ACCESS_TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh-token';

@Injectable({
    providedIn: 'root'
})

export class TokenService {
    constructor() { } 

    getToken(): string{
        const token = localStorage.getItem(ACCESS_TOKEN);
        const expiration = localStorage.getItem(`${ACCESS_TOKEN}_expiration`);
        if (token && expiration && Date.now() < parseInt(expiration, 10)) {
            return token;
        } else {
            return null;
        }
    }

    getRefreshToken(): string{
        return localStorage.getItem(REFRESH_TOKEN)!;
    }

    saveToken(token: any): void{
        localStorage.setItem(ACCESS_TOKEN, token);
        localStorage.setItem(`${ACCESS_TOKEN}_expiration`, (Date.now() + 1800000).toString()); // expire in 30min
    }

    saveRefreshToken(refreshToken: any): void{
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }

    removeToken(): void{
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(`${ACCESS_TOKEN}_expiration`);
    }

    removeRefreshToken(): void{
        localStorage.removeItem(REFRESH_TOKEN);
    }
}


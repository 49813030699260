import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SelectedGraphicService {
  private selectedGraphicIdSource = new BehaviorSubject<number>(0);
  selectedGraphicId$ = this.selectedGraphicIdSource.asObservable();

  setSelectedGraphicId(graphicId: number){
    this.selectedGraphicIdSource.next(graphicId);
  }

  private selectedGraphicUrlSource = new BehaviorSubject<string>('');
  selectedGraphicUrl$ = this.selectedGraphicUrlSource.asObservable();

  setSelectedGraphicUrl(graphicUrl: string){
    this.selectedGraphicUrlSource.next(graphicUrl);
  }

  private selectedGraphicSizeSource = new BehaviorSubject<number>(0);
  selectedGraphicSize$ = this.selectedGraphicSizeSource.asObservable();

  setSelectedGraphicSize(graphicSize: number){
    this.selectedGraphicSizeSource.next(graphicSize);
  }

  private selectedGraphicHASource = new BehaviorSubject<string>('');
  selectedGraphicHA$ = this.selectedGraphicHASource.asObservable();

  setSelectedGraphicHA(graphicHA: string){
    this.selectedGraphicHASource.next(graphicHA);
  }

  private selectedGraphicVASource = new BehaviorSubject<string>('');
  selectedGraphicVA$ = this.selectedGraphicVASource.asObservable();

  setSelectedGraphicVA(graphicVA: string){
    this.selectedGraphicVASource.next(graphicVA);
  }
}
import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SelectedTeamService } from 'src/app/containers/layout/topnav/selected-team.service';
import { SelectedGroupService } from 'src/app/views/app/teams-page/selected-group-service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-remove-user',
  templateUrl: './modal-remove-user.component.html'
})

export class ModalRemoveUserComponent implements OnInit{
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  private selectedWorkspaceId: number;
  private selectedGroupMemberId: number;
  selectedGroupName: string;
  selectedGroupMemberName: string;

  constructor(private modalService: BsModalService,
    private selectedGroupService: SelectedGroupService, 
    private selectedTeamService: SelectedTeamService,
    private tokenService: TokenService,
    private http: HttpClient, 
    private dataService: DataService,
    private notifications: NotificationsService) { }
  
    ngOnInit(): void {
      this.selectedTeamService.selectedTeamId$.subscribe(workspaceId =>  // Get Selected workspaceId
        this.selectedWorkspaceId = workspaceId );
      this.selectedGroupService.selectedGroupMemberId$.subscribe(groupMemberId => // Get selected groupMemberId
        this.selectedGroupMemberId = groupMemberId );
      this.selectedGroupService.selectedGroupName$.subscribe(groupName => 
        this.selectedGroupName = groupName);
      this.selectedGroupService.selectedGroupMemberName$.subscribe(memberName => 
        this.selectedGroupMemberName = memberName);
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void {
    this.http
    .delete(`${environment.apiUrl}/v1/groups/members/${this.selectedGroupMemberId}?teamId=${this.selectedWorkspaceId}`, { 
      headers: { Authorization: this.authHeader } })
    .subscribe((resData) => { 
      this.modalRef.hide();
      this.dataService.notifyDataUploaded();
      this.notifications.create('User removed', 'User successfully removed from the team!', 
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })},
    (error) => {
      this.modalRef.hide();
      this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })}
    );  
  }
}

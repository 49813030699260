import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DataService } from '../data-service';
import { TokenService } from 'src/app/shared/token.service';

@Component({
  selector: 'app-modal-invitations',
  templateUrl: './modal-invitations.component.html',
})

export class ModalInvitationsComponent implements OnInit {
  private authHeader = this.tokenService.getToken(); 
  modalRef: BsModalRef;
  pendingInvitations: number;
  teams: {logo: string, name: string, token: string}[];
  hasInvitations: boolean = false;

  constructor(private modalService: BsModalService,
    private http: HttpClient,
    private tokenService: TokenService,
    private dataService: DataService,
    private notifications: NotificationsService) { }

    ngOnInit(): void {
      this.joinTeam();
      this.dataService.dataUploaded$.subscribe(() => this.joinTeam());
    }

    joinTeam(){ 
      this.http
      .get(`${environment.apiUrl}/v1/user-invitations`, {headers: { Authorization: this.authHeader }})
      .subscribe((resData: any) => { 
        this.hasInvitations = Array.isArray(resData) && resData.length > 0;
        this.teams = resData.map(item => ({
          logo: item.team.logo, name: item.team.name, token: item.token }));
        if (this.teams.length === 0) { // No invitations, close modal
          this.modalRef.hide();
        } else { // Set number of pending invitations
          this.pendingInvitations = this.teams.length;
      }});  
    }
    
    acceptInvitation(token: string){
      this.http
      .post(`${environment.apiUrl}/v1/user-invitations/${token}`, {}, {headers: {Authorization: this.authHeader}})
      .subscribe((resData) => { 
        this.dataService.notifyDataUploaded();
        this.notifications.create('Workspace invitation accepted', 'Workspace invitation successfully accepted. You are now a member of the workspace!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        this.pendingInvitations--; // Decrease number of pending invitations
        if (this.pendingInvitations === 0) { // No pending invitations left, close modal
          this.modalRef.hide();
      }},
      (error) => ( this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false }))
      );    
    }
    
    rejectInvitation(token: string){
      this.http
      .delete(`${environment.apiUrl}/v1/user-invitations/${token}`, {headers: {Authorization: this.authHeader}})
      .subscribe((resData) => { 
        this.dataService.notifyDataUploaded();
        this.notifications.create('Workspace invitation rejected', 'Workspace invitation successfully rejected. You are not a member of the workspace!', 
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        this.pendingInvitations--; // Decrease number of pending invitations
        if (this.pendingInvitations === 0) { // No pending invitations left, close modal
          this.modalRef.hide();
      }},
      (error) => ( this.notifications.create('Error', error.error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false })) 
      );      
    }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }
}

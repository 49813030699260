import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'; // to mark the logo url as safe to use
import { SelectedTeamService } from './selected-team.service';
import { TokenService } from 'src/app/shared/token.service';
import { DataService } from '../../ui/modals/data-service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})

export class TopnavComponent implements OnInit, OnDestroy {
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  selectedTeam: { id: number, name: string, logo: SafeUrl };
  displayName: string = 'Display name';
  teamLogoSrc: SafeUrl = this.sanitizer.bypassSecurityTrustUrl('/assets/img/profiles/l-12.jpg');

  constructor(private selectedTeamService: SelectedTeamService, 
          private sanitizer: DomSanitizer, 
          private http: HttpClient, 
          private tokenService: TokenService,
          private sidebarService: SidebarService, 
          private router: Router, 
          private dataService: DataService ) {
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    this.teams = null;
    this.selectedTeam = null;
  }

  initializeTeam(name: string, logo: string, userRole: string) {
    this.selectedTeamService.setSelectedTeamId(this.teamId);
    this.selectedTeamService.setSelectedTeamName(name);
    this.selectedTeamService.setSelectedTeamLogo(logo);
    this.selectedTeamService.setUserRole(userRole); 
    this.displayName = name;
    this.teamLogoSrc = logo;
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  teamId: number;
  async ngOnInit(): Promise<void> {
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => { this.sidebar = res; },
      (err) => { console.error(`An error occurred: ${err.message}`); }
    );
    this.teamId = Number(localStorage.getItem("teamId"));
    this.listMyTeams(); 
    this.getMyTeam();
    this.dataService.dataUploaded$.subscribe(() => 
      this.listMyTeams() );
  }

  teams: any[] = [];
  findPreselectedTeam: any[] = [];
  preselectedTeam: any;
  listMyTeams(): void{ // API Call to get all the teams for user
    let authHeader = this.tokenService.getToken();
    if (authHeader) { 
      this.http
        .get(`${environment.apiUrl}/v1/users/my-teams`, { headers:{Authorization: authHeader} } )
        .subscribe((resData: any) => { 
          this.teams = resData;
      });
    } 
  }
  getMyTeam(): void{ // API Call to get all the teams for user
    let authHeader = this.tokenService.getToken();
    if (authHeader) { 
      this.http
        .get(`${environment.apiUrl}/v1/users/my-teams`, { headers:{Authorization: authHeader} } )
        .subscribe((resData: any) => { 
          this.findPreselectedTeam = resData;
          this.preselectedTeam = this.findPreselectedTeam.find(team => team.team.id === this.teamId);
          this.selectedTeamService.setSelectedTeamName(this.preselectedTeam.team.name);
          this.selectedTeamService.setSelectedTeamLogo(this.preselectedTeam.team.logo);
          this.selectedTeamService.setUserRole(this.preselectedTeam.userRole);
          this.initializeTeam(this.preselectedTeam.team.name, this.preselectedTeam.team.logo, this.preselectedTeam.userRole);
      });
    } 
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = ( e: { stopPropagation: () => void }, menuClickCount: number, containerClassnames: string ) => {
    if (e) {
      e.stopPropagation();
    }
    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);
    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = ( event: { stopPropagation: () => void }, containerClassnames: string ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut(): void {
    localStorage.clear();
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    this.router.navigate(['/user/login']);
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }

  updateSelectedTeam(team: any) {
    this.selectedTeam = {
      id: team.team.id, name: team.team.name, logo: team.team.logo
    };
    this.displayName = team.team.name; // update display name,team logo
    this.teamLogoSrc = team.team.logo; 

    this.selectedTeamService.setSelectedTeamName(team.team.name); // Set Team Information
    this.selectedTeamService.setSelectedTeamId(team.team.id); 
    this.selectedTeamService.setSelectedTeamDomain(team.team.domain); 
    this.selectedTeamService.setSelectedTeamLogo(team.team.logo);
    this.selectedTeamService.setUserRole(team.userRole); 
    this.router.navigate(['/app/' + team.team.id + '/templates-page']);
    localStorage.setItem('teamId', JSON.stringify(team.team.id));
  }
}